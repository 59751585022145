@use "./assets/scss/reset.scss";
@use 'bootstrap/scss/bootstrap.scss';
@use "./assets/scss/variables.scss";

// general
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    
}
::placeholder {
    
}
body{
    background-color: #D9D9D9;
    position: fixed;
    overflow: hidden;

}
body * {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    user-select: none;
}
input{
    user-select: all;
}
main {
    min-height: 100vh;
    color: variables.$text-color;
}
.saving{
    opacity: .75;
    pointer-events: none;
}
.saving--pre{
    transition: .1s ease;
}
.no-pointer{
    pointer-events: none;
}

// font sizes @feedback
@media screen and (min-width: 400px) {
    .slideout-head .title{
        // font-size: 16px !important;
    }
    .head-wrap .select-date>div{
        font-size: 16px !important;
        transform: translatey(1px) !important;
    }
    .slideout-switch .option{
        height: 28px !important;
        font-size: 14px !important;
        &>span{
            font-size: 14px !important;
        }
        .notif--jobs-to-accept{
            width: 12px !important;
            height: 12px !important;
            top: 0 !important;
        }
    }
    .app-button button{
        min-height: 32px !important;
        font-size: 16px !important;
    }
}

// vertical height small phones @feedback
@media only screen and (max-height: 600px) {
    .select-date{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .weekDays{
        height: 22px !important;
        margin-bottom: 8px !important;
    }
    .day--calendar{
        margin-bottom: 6px !important;
        padding-bottom: 2px !important;
    }
    .slideout{
        padding: 6px 8px !important;
    }
    .slideout-switch{
        margin-top: 6px;
    }
    .slideout-content{
        margin-top: 8px !important;
    }
    .slideout-panel{
        padding: 12px 8px !important;
    }
    .slideout-panel--availability--set .title{
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }
    .app-button{
        margin-bottom: 10px !important;
    }
    .app-button button{
        min-height: 28px !important;
    }
    .option-wrap .option{
        height: 25px !important;
    }
}